<template>
  <div>
    <!-- Versión escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
          <v-btn v-if="canEdit" rounded dark class="common-botton" @click="changeComponent">
            <span style="padding-right: 11%;">
              Editar
            </span>
            <v-icon rigth>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Nombre(s)
          </span>
          <br />
          <span>
            {{ personalData.nombre ? personalData.nombre : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Apellido Paterno
          </span>
          <br />
          <span>
            {{
              personalData.apellidoPaterno
                ? personalData.apellidoPaterno
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Apellido Materno
          </span>
          <br />
          <span>
            {{
              personalData.apellidoMaterno
                ? personalData.apellidoMaterno
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Genero
          </span>
          <br />
          <span>
            {{ getGenero(personalData.sexo) }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Fecha de nacimiento
          </span>
          <br />
          <span>
            {{ formatDate(personalData.fechaNacimiento) }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Lugar de nacimiento
          </span>
          <br />
          <span>
            {{ getStateName(personalData.nacimientoEstadoId) }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            RFC
          </span>
          <br />
          <span>
            {{ personalData.rfc ? personalData.rfc : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData">
          <span class="text-header-data">
            Rol
          </span>
          <br />
          <span>
            {{ getRol(personalData.rol) }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData && personalData.rol != 'AGENT'">
          <span class="text-header-data">
            Área de negocio
          </span>
          <br />
          <span>
            {{ areaNegocioText }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData">
          <span class="text-header-data">
            Estatus
          </span>
          <br />
          <span>
            {{ getStatus(personalData.status) }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData && personalData.status == -1">
          <span class="text-header-data">
            Motivo de baja
          </span>
          <br />
          <span>
            {{ personalData.motivoBaja }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData" @click="openMenuMethod" style="position:relative">
          <v-menu v-model="openMenu" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" style="visibility: hidden; position: absolute;">Open Menu</v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in listings.ramoList" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.ramo }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox v-model="personalData.ramos" :value="item.id"
                    :input-value="personalData.ramos.includes(item.id)" disabled></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-select v-model="personalData.ramos" :items="listings.ramoList" item-text="ramo" item-value="id"
            label="Ramo" multiple readonly outlined background-color="white" color="#00a7e4">
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.etiqueta }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ personalData.ramos.length - 1 }} más)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData">
          <v-select v-model="personalData.canales" :items="listings.canalList" label="Canal" item-text="etiqueta"
            item-value="id" multiple readonly outlined background-color="white" color="#00a7e4">
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.etiqueta }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ personalData.canales.length - 1 }} más)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4"  v-if="
            personalData.rol == 'BACKOFFICEEMAILS' ||
            personalData.rol == 'MANAGEREMAILS' ||
            personalData.rol == 'OPERADOREMAILS' ||
            personalData.rol == 'OPERACIONESEMAILS'
        " >
          <v-select v-model="personalData.cuentas_correo" :items="cuentasCorreo" label="Cuentas correo" item-text="cuenta"
            item-value="id" multiple readonly outlined background-color="white" color="#00a7e4">
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.cuenta }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ personalData.cuentas_correo.length - 1 }} más)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" v-if="routesConfig.completeData && personalData.status == -1">
          <span class="text-header-data">
            Nivel de Agente
          </span>
          <br />
          <span>
            {{ personalData.nivelAgente }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" v-if="
          routesConfig.completeData &&
          (personalData.rol == 'ADMINCC' ||
            personalData.rol == 'MANAGERCC' ||
            personalData.rol == 'AGENTCC')
        ">
          <v-select v-model="personalData.landings" :items="listings.landings" label="Landing" item-value="id"
            item-text="landing" multiple readonly outlined background-color="white" color="#00a7e4">
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.landing }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ personalData.landings.length - 1 }} más)
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="personalData.rol == 'AGENT'">
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Fuente de Ingreso
          </span>
          <br />
          <span>
            {{
              personalData.fuenteIngreso
                ? personalData.fuenteIngreso
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Regimen
          </span>
          <br />
          <span>
            {{ personalData.regimen ? personalData.regimen : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <v-select label="Agente asociado a una promotoria" v-model="personalData.agenteAsociadoPromotoria"
            :items="listings.asociadoPromotoriaItems" item-text="name" item-value="id" multiple readonly outlined
            background-color="white" color="#00a7e4">
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ personalData.agenteAsociadoPromotoria.length - 1 }} más)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Tipo de Agente
          </span>
          <br />
          <span>
            {{ personalData.tipoAgente ? personalData.tipoAgente : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Razón Social
          </span>
          <br />
          <span>
            {{
              personalData.razonSocial ? personalData.razonSocial : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data ">
            Clabe Bancaria para transferencia
          </span>
          <br />
          <span>
            {{
              personalData.clabeBancaria
                ? personalData.clabeBancaria
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Clave del Agente
          </span>
          <br />
          <span>
            {{
              personalData.claveAgente ? personalData.claveAgente : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Tipo de Cédula
          </span>
          <br />
          <span>
            {{ personalData.tipoCedula ? personalData.tipoCedula : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Nivel Agente
          </span>
          <br />
          <span>
            {{
              personalData.nivelAgente ? personalData.nivelAgente : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Número de Cartera
          </span>
          <br />
          <span>
            {{
              personalData.numeroCartera
                ? personalData.numeroCartera
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Cedula
          </span>
          <br />
          <span>
            {{ personalData.cedula ? personalData.cedula : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            RC
          </span>
          <br />
          <span>
            {{ personalData.rc ? personalData.rc : "Sin llenar" }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Vigencia de Cédula
          </span>
          <br />
          <span>
            {{
              personalData.vigenciaCedula
                ? personalData.vigenciaCedula
                : "Sin llenar"
            }}
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
          <span class="text-header-data ">
            Vigencia póliza RC
          </span>
          <br />
          <span>
            {{
              personalData.vigenciaPolizaRc
                ? personalData.vigenciaPolizaRc
                : "Sin llenar"
            }}
          </span>
        </v-col>
      </v-row>
    </template>
    <!-- Versión móvil -->
    <template v-else>
      <div class="mobile-container">
        <v-row>
          <v-col cols="12">
            <span class="text-header-data">Nombre(s)</span>
            <br />
            <span>{{ personalData.nombre ? personalData.nombre : "Sin llenar" }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">Apellido Paterno</span>
            <br />
            <span>{{ personalData.apellidoPaterno ? personalData.apellidoPaterno : "Sin llenar" }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">Apellido Materno</span>
            <br />
            <span>{{ personalData.apellidoMaterno ? personalData.apellidoMaterno : "Sin llenar" }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">Género</span>
            <br />
            <span>{{ getGenero(personalData.sexo) }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">Fecha de nacimiento</span>
            <br />
            <span>{{ formatDate(personalData.fechaNacimiento) }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">Lugar de nacimiento</span>
            <br />
            <span>{{ getStateName(personalData.nacimientoEstadoId) }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text-header-data">RFC</span>
            <br />
            <span>{{ personalData.rfc ? personalData.rfc : "Sin llenar" }}</span>
          </v-col>
          <v-col cols="12" v-if="routesConfig.completeData">
            <span class="text-header-data">Rol</span>
            <br />
            <span>{{ getRol(personalData.rol) }}</span>
          </v-col>
          <v-col cols="12" v-if="routesConfig.completeData">
            <span class="text-header-data">Área de negocio</span>
            <br />
            <span>{{ areaNegocioText }}</span>
          </v-col>
          <v-col cols="12" v-if="routesConfig.completeData">
            <span class="text-header-data">Estatus</span>
            <br />
            <span>{{ getStatus(personalData.status) }}</span>
          </v-col>
          <v-col cols="12" v-if="canEdit">
            <v-btn
              rounded
              dark
              class="mobile-action-button"
              @click="changeComponent"
            >
              <span>Editar</span>
              <v-icon rigth>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>

</template>

<script>
import moment from "moment"; // Importa la librería para formateo de fechas
import { getAreaNegocio } from "@/services/log/log.service.js"; // Servicio para áreas de negocio
import {
  getCuentasCorreoListByParams
} from "@/services/cuentasCorreo/cuentasCorreo.service.js";

export default {
  props: {
    // Propiedad con datos personales
    personalData: {
      type: Object,
      default: () => {},
    },
    // Lista de estados
    stateList: Array,
    routesConfig: Object, // Configuración de rutas
    listings: Object, // Listas predefinidas
    canEdit: { // Permiso para editar
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openMenu: false, // Estado para el menú
      areasNegocio: [], // Áreas de negocio disponibles
      areaNegocioText: "", // Texto de área de negocio
      cuentasCorreo:[]
    };
  },
  methods: {
    // Alterna el estado del menú
    openMenuMethod() {
      this.openMenu = !this.openMenu;
    },
    // Cambia el componente al que se navega
    changeComponent() {
      this.$emit("changeComponent", 2);
    },
    // Devuelve "Masculino" o "Femenino" basado en el valor
    getGenero(value) {
      return value == 1 ? "Masculino" : value == 2 ? "Femenino" : "";
    },
    // Devuelve el nombre del estado basado en el ID
    getStateName(id) {
        const state = this.stateList.find((e) => e.cEstado == id);
        return state ? state.dEstado : "No disponible";
    },
    // Formatea la fecha al formato DD/MM/YYYY
    formatDate(value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    // Obtiene el rol basado en el ID
    getRol(value) {
      return this.listings.roles.find((e) => e.id == value)?.nombre ?? "No disponible";
    },
    // Obtiene el estatus basado en el ID
    getStatus(value) {
      return this.listings.status.find((e) => e.id == value)?.name ?? "No disponible";
    },
    // Obtiene y formatea texto del área de negocio
    getAreaNegocio(value) {
      const areaNegocio = this.listings.roles.find((e) => e.id == value)?.area_negocio;
      if (areaNegocio) {
        const area = this.areasNegocio.find((e) => e.id == areaNegocio);
        this.areaNegocioText = area
          ? `${area.areas} - ${area.departamento} - ${area.proceso}`
          : "El rol no tiene área de negocio asignado";
      } else {
        this.areaNegocioText = "El rol no tiene área de negocio asignado";
      }
    },
    async getCuentasCorreoListByParams(){
      var body = {
        ...{
          pagina: 1,
          elementosPagina: 100,
        },
      };
      let response = await getCuentasCorreoListByParams(body)
      this.cuentasCorreo = response.cuentas ?? []
    }
  },
  watch: {
    // Actualiza el texto del área de negocio cuando cambian los datos
    areasNegocio() {
      this.getAreaNegocio(this.personalData.rol);
    },
    "personalData.rol"(value) {
      this.getAreaNegocio(value);
    },
  },
  async mounted() {
    // Carga inicial de áreas de negocio
    this.areasNegocio = await getAreaNegocio();
    this.getAreaNegocio(this.personalData.rol);
    this.getCuentasCorreoListByParams();

  },
};
</script>

<style scoped>
/* Estilos para móviles */
@media (max-width: 959px) {
  .mobile-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }

  .mobile-actions {
    margin-top: 16px;
    text-align: center;
  }

  .mobile-action-button {
    background-color: #00a7e4 !important;
    color: white;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }

  .mobile-action-button v-icon {
    margin-right: 10px;
  }
}
</style>
